import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
    setActivityAction,
    setInCallAction,
    setCallStart,
    setCallEnd,
    setPendingEmail,
    setPendingRaiseCaseRequest,
    setPendingConversation,
} from "../redux/actions/flexActions";
import {
    unassignActivity,
    setFlexWindowStatus,
    setConfirmCompleteTaskInfo,
    addCaseEventAction,
    incrementEventCount,
    setEmails,
    setCaseActivityIds,
} from "../redux/actions/caseActions";
import {
    setFullScreenEmail,
    addAcceptedEmailTaskSid,
    removeAcceptedEmailTaskSid,
    setActiveFlexEmailTaskSid,
    setUserTimerRunning, setSeekAdviceMiscActivityId, setRaiseCaseRequestId,
} from "../redux/actions/userActions";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { createCaseActionCreators } from "../redux/reducers/createCaseReducer";
import { actionCreators as accountActionCreators } from "../redux/reducers/accountReducer";
import {
    setCreateContactEmail,
    setCreateContactPhone,
} from "../redux/actions/createContactActions";
import { createContactActionCreators } from "../redux/reducers/createContactReducer";
import { setSnackAction } from "../redux/actions/snackActions";
import axios from "../plugins/axios";
import {
    setCallbackRequestData,
    setCallbackRequestOpen,
} from "../redux/actions/accountActions";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import caseActivityService from "../services/caseActivityService";
import caseActivityTypes from "../constants/caseActivityTypes";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const FlexContainer = ({
    setOpen,
    setTaskSid,
    getCase,
    setInCall,
    setActivity,
    ssoPhrase,
    unassignActivity,
    setFlexWindowStatus,
    caseState,
    userState,
    setCreateCaseAccount,
    setCreateCaseAccountAndContact,
    setCreateContactPhone,
    setCreateContactEmail,
    setCreateContactAccount,
    setConfirmCompleteTaskInfo,
    setFullScreenEmail,
    addAcceptedEmailTaskSid,
    removeAcceptedEmailTaskSid,
    setActiveFlexEmailTaskSid,
    getAccount,
    setUserTimerRunning,
    width,
    getAllCaseActivities,
}) => {
    const dispatch = useDispatch();
    const caseIdRef = useRef(null);
    const userTimerRunningRef = useRef(null);
    const casesRef = useRef({});

    const { hasRole } = useAuth();

    const history = useHistory();

    const handleFocus = () => {
        setFlexWindowStatus("focus");
    };

    const handleBlur = () => {
        setFlexWindowStatus("blur");
    };

    useEffect(() => {
        caseIdRef.current = caseState.currentCaseId;
    }, [caseState.currentCaseId]);

    useEffect(() => {
        userTimerRunningRef.current = userState.isUserTimerRunning;
    }, [userState.isUserTimerRunning]);

    useEffect(() => {
        casesRef.current = caseState.cases;
    }, [caseState.cases]);

    useEffect(() => {
        const receiveMessage = async (ev) => {
            const message = ev.data;
        
            if (message.event) {
                console.log('received', ev.data);
                setOpen();
            }

            if (message.event === "voice disconnect") {
                setInCall({ inCall: false });
            }

            if (message.event === "activityUpdated")
                setActivity(message.activity);

            if (message.event === "reservationCreated") {
                setTaskSid(message.sid);
            }
            
            if (message.event === "openCase") {
                const caseId = parseInt(message.caseId, 10);
                if (message.channelType === "email") {
                    const contractId = casesRef.current[caseId]?.caseSummary.contractId || "";
                    let response = await axios.get(`${apiUrl}/getemail/${message.taskSid}`);
                    let response2 = await axios.get(`${apiUrl}/assignemail/${message.caseId}/${message.taskSid}?contractId=${contractId}`);
                    if (response2.data.caseActivityId) {
                        window.frames[0].postMessage(
                            {
                                event: "emailAssigned",
                                taskSid: message.taskSid,
                                caseId: message.caseId,
                            },
                            "*"
                        );

                        if (casesRef.current[caseId]) {
                            dispatch(
                                addCaseEventAction(caseId, {
                                    caseActivityId: response2.data.caseActivityId,
                                    adviserId: userState.userProfile.userId,
                                    itemType: caseActivityTypes.EMAIL,
                                    eventTime: response2.data.emailCreated,
                                    direction: 0,
                                    person: response.data.person,
                                    content: response.data.content,
                                    toAddress: response.data.toAddress,
                                    attachments: response.data.attachments,
                                })
                            );

                            dispatch(incrementEventCount(caseId));

                            dispatch(
                                setEmails(
                                    casesRef.current[caseId].emails.map((e) =>
                                        e.taskSid === message.taskSid
                                            ? {
                                                  ...e,
                                                  caseActivityIds: [
                                                      ...e.caseActivityIds,
                                                      response2.data.caseActivityId,
                                                  ],
                                              }
                                            : e
                                    ),
                                    caseId
                                )
                            );
                            dispatch(setCaseActivityIds([...casesRef.current[caseId].caseActivityIds, response2.data.caseActivityId], caseId));
                            window.frames[0].postMessage({ event: "caseOpened" }, "*");
                            return;
                        }
                    }
                }                

                if (message.channelType === "seek-advice" && message.shouldCreateActivity) {
                    try {
                        await caseActivityService.addSeekAdviceActivity({
                            taskSid: message.taskSid,
                            caseId,
                        });
                        window.frames[0].postMessage(
                            {
                                event: "emailAssigned",
                                taskSid: message.taskSid,
                                caseId: message.caseId,
                            },
                            "*"
                        );
                    }
                    catch (e) {
                        console.error(e);
                        dispatch(setSnackAction(e?.message || "Failed to add Seek Advice Activity", "error"));
                    }
                }

                if (casesRef.current[caseId] && message.channelType !== "email")
                    await getAllCaseActivities(caseId);

                await getCase(message.caseId, history);

                window.frames[0].postMessage({ event: "caseOpened" }, "*");
            }

            if (message.event === "unassignEmail") {
                let response = await axios.get(`${apiUrl}/unassignemail/${message.caseId}/${message.taskSid}`);
                let caseActivityId = response.data;

                if (response.status === 200) {
                    unassignActivity(caseActivityId, message.caseId);
                    window.frames[0].postMessage(
                        {
                            event: "emailUnassigned",
                            taskSid: message.taskSid,
                            caseId: message.caseId,
                        },
                        "*"
                    );
                }
            }

            if (message.event === "unassignChat") {
                let response = await axios.get(`${apiUrl}/unassignchat/${message.caseId}/${message.taskSid}`);
                let caseActivityId = response.data;

                if (response.status === 200) {
                    unassignActivity(caseActivityId, message.caseId);
                    window.frames[0].postMessage(
                        {
                            event: "emailUnassigned",
                            taskSid: message.taskSid,
                            caseId: message.caseId,
                        },
                        "*"
                    );
                }
            }

            if (message.event === "flexWindowFocussed")
                handleFocus();            

            if (message.event === "flexWindowBlurred")
                handleBlur();            

            if (message.event === "newCase") {
                if (message.channelType === "email")
                    dispatch(setPendingEmail(message.taskId));

                if (message.channelType === "seek-advice")
                    dispatch(setPendingRaiseCaseRequest(message.taskId));

                if (message.channelType === "chat")
                    dispatch(setPendingConversation(message.taskId));

                if (message.accountId && message.contactId)
                    setCreateCaseAccountAndContact(message.accountId, message.contactId, hasRole(userRoles.LEGAL_ADVISOR));
                else if (message.accountId)
                    setCreateCaseAccount(message.accountId);

                history.push("/createcase");
            }

            if (message.event === "reservationAccepted") {
                if (userTimerRunningRef.current)
                    setUserTimerRunning(false);

                setInCall({ inCall: true, callId: message.callId });
            }

            if (message.event === "confirmCompleteTask") {
                setConfirmCompleteTaskInfo(
                    true,
                    message.channelType,
                    message.sid,
                    message.emailAllocated || false,
                    message.taskSid
                );
            }

            if (message.event === "newContact") {
                if (message.accountId)
                    setCreateContactAccount(message.accountId, false);

                if (message.email)
                    setCreateContactEmail(message.email);

                if (message.phone)
                    setCreateContactPhone(message.phone);

                history.push("/createcontact");
            }

            if (message.event === "setContactFields") {
                setCreateContactPhone(message.phone ? message.phone : "");
                setCreateContactEmail(message.email ? message.email : "");
            }

            if (message.event === "previewEmail") {
                let response = await axios.get(`${apiUrl}/getemail/${message.taskSid}`);
                if (!response.data) {
                    dispatch(
                        setSnackAction(
                            "Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support",
                            "error"
                        )
                    );
                    return;
                }

                setFullScreenEmail(
                    { ...response.data, partner: message.partner || null },
                    false,
                    true
                );
            }
            
            if (message.event === "previewSeekAdvice" && !!message.caseActivityMiscId) {
                dispatch(setSeekAdviceMiscActivityId(message.caseActivityMiscId));
            }
            
            if (message.event === "previewSeekAdvice" && !!message.raiseCaseRequestId) {
                dispatch(setRaiseCaseRequestId(message.raiseCaseRequestId));
            }

            if (message.event === "emailAccepted")
                addAcceptedEmailTaskSid(message.taskSid);

            if (message.event === "emailCompleted")
                removeAcceptedEmailTaskSid(message.taskSid);

            if (message.event === "activeEmailChanged")
                setActiveFlexEmailTaskSid(message.taskSid);

            if (message.event === "openAccount") {
                getAccount(message.accountId, history);
            }

            if (message.event === "newCallback") {
                if (!message.accountId) return;

                getAccount(message.accountId, history);

                // set callback name, contactId, phone number
                dispatch(
                    setCallbackRequestData({
                        contactId: message.contactId,
                        name: message.name,
                        phone: message.phone,
                    })
                );

                // set callback modal open
                dispatch(setCallbackRequestOpen(true));
            }
        };

        window.addEventListener("message", receiveMessage, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <iframe
            title="flex"
            width={width}
            height="100%"
            src={ssoPhrase ? (`https://flex.twilio.com/${ssoPhrase}?path=/agent-desktop`) : "http://localhost:4000"}
            id="flex-iframe"
            allow="camera;microphone;autoplay"
        ></iframe>
    );
};

const mapStateToProps = (state) => ({
    ssoPhrase: state.configReducer.serverValues.ssoPhrase,
    caseState: state.caseReducer,
    userState: state.userReducer,
    configState: state.configReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        ...bindActionCreators(createCaseActionCreators, dispatch),
        ...bindActionCreators(createContactActionCreators, dispatch),
        ...bindActionCreators(accountActionCreators, dispatch),
        setInCall: (payload) => dispatch(setInCallAction(payload)),
        setActivity: (activity) => dispatch(setActivityAction(activity)),
        unassignActivity: (caseActivityId, prevCaseId) => dispatch(unassignActivity(caseActivityId, prevCaseId)),
        setFlexWindowStatus: (status) => dispatch(setFlexWindowStatus(status)),
        setCallStart: (payload) => dispatch(setCallStart(payload)),
        setCallEnd: () => dispatch(setCallEnd()),
        setCreateContactPhone: (phone) =>  dispatch(setCreateContactPhone(phone)),
        setCreateContactEmail: (email) => dispatch(setCreateContactEmail(email)),
        setConfirmCompleteTaskInfo: (isOpen, channelType, sid, isEmailAllocated, taskSid) => dispatch(setConfirmCompleteTaskInfo(isOpen, channelType, sid, isEmailAllocated, taskSid)),
        setFullScreenEmail: (email) => dispatch(setFullScreenEmail(email, false, true)),
        addAcceptedEmailTaskSid: (taskSid) => dispatch(addAcceptedEmailTaskSid(taskSid)),
        removeAcceptedEmailTaskSid: (taskSid) => dispatch(removeAcceptedEmailTaskSid(taskSid)),
        setActiveFlexEmailTaskSid: (taskSid) => dispatch(setActiveFlexEmailTaskSid(taskSid)),
        setUserTimerRunning: (isRunning) => dispatch(setUserTimerRunning(isRunning)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlexContainer);
