import * as types from '../constants';
import store from '../store';

export function dial(number, contactId, accountId, outboundNumber, recordingDisabled) {
    var valid = /^([0-9]|#|\*)+$/.test(number.replace(/[-()+\s]/g, ''));
    if (!valid)
        return {
            type: types.SET_SNACK,
            snack: {
                message: "Number not valid",
                severity: "error"
            }
        }

    var flexState = store.getState().flexReducer;

    if (flexState.inCall)
        return {
            type: types.SET_SNACK,
            snack: {
                message: "Call currently in progress.",
                severity: "error"
            }
        }

    if (flexState.activity === '')
        return {
            type: types.SET_SNACK,
            snack: {
                message: "Flex has not yet started",
                severity: "error"
            }
        }

    if (flexState.activity === 'Offline')
        return {
            type: types.SET_SNACK,
            snack: {
                message: "You cannot dial whilst offline",
                severity: "error"
            }
        }

    return {
        type: types.DIAL,
        number,
        contactId,
        accountId,
        outboundNumber,
        recordingDisabled
    }
}

export function setActivityAction(activity) {
    return {
        type: types.SET_FLEX_ACTIVITY,
        activity: activity
    }
}

export function setInCallAction({ inCall, callId }) {
    return {
        type: types.SET_IN_CALL,
        payload: {
            inCall,
            callId,
        }
    }
}

export function setCallStart({ callId }) {
    return {
        type: types.SET_CALL_START,
        payload: {
            callId,
        }
    }
}

export function setCallEnd() {
    return {
        type: types.SET_CALL_END
    }
}

export function setPendingEmail(taskSid) {
    return {
        type: types.SET_PENDING_EMAIL,
        taskSid
    }
}

export function setPendingConversation(taskSid) {
    return {
        type: types.SET_PENDING_CONVERSATION,
        taskSid
    }
}

export function clearUnallocatedCalls() {
    return {
        type: types.CLEAR_UNALLOCATED_CALLS
    }
}

export function setPendingRaiseCaseRequest(taskSid) {
    return {
        type: types.SET_PENDING_RAISE_CASE_REQUEST,
        taskSid
    }
}